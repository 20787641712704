/* eslint-disable brace-style */
/* eslint-disable no-cond-assign */

/**
 *  @fileOverview Browser detection
 *
 *  @author       Sebastian Dorminger <Sebastian.Dorminger@mirumagency.com>
 *
 */

class BrowserDetect {
  init() {
    this.deprecatedInit(); // TODO: to be removed
    const currentBrowser = this.sayWho();
    const currentOs = this.whichOS();
    const browser = currentBrowser.browser;
    const version = currentBrowser.version;

    document.documentElement.classList.add(browser, currentOs);
    document.body.setAttribute('data-browser', `${browser}|${version}`);
  }

  deprecatedInit() {
    if (/iP(ad|hone|od).+Version\/[d.]+.*Safari/i.test(navigator.userAgent)) {
      document.documentElement.classList.add('mobile-safari');
    }
    if (/(Macintosh|iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
      document.documentElement.classList.add('mac-os');
    }
    if (/AppleWebKit/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)) {
      document.documentElement.classList.add('safari');
    }
    if (/(MSIE|Trident|Edge)/i.test(navigator.userAgent)) {
      document.documentElement.classList.add('ie');
    }
    if (/Firefox/i.test(navigator.userAgent)) {
      document.documentElement.classList.add('ff');
    }
  }

  sayWho() {
    const ua = navigator.userAgent;
    let tem = false;
    let M = ua.match(/(opera|CriOS|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    const mobile =
      ua.match(/android/i) ||
      ua.match(/ip(ad|od|hone)/i) ||
      ua.match(/iemobile/i) ? 'mobile' : '';

    // Check if browser has trident engine
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {
        browser: `${mobile}ie`,
        version: tem[1] || '',
      };
    }
    // Check if UA has Chrome phrase
    else if (M[1] === 'Chrome') {
      // Check if it's Opera or Edge
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem !== null) {
        return {
          browser: mobile + tem.slice(1)[0].replace('OPR', 'opera').toLowerCase(),
          version: tem.slice(1)[1],
        };
      }
    }
    // Check if the browser is Mobile Opera
    else if ((tem = ua.match(/(?:Opera\/)(\d+)/i)) !== null) {
      return {
        browser: `${mobile}opera`,
        version: tem[1],
      };
    }
    // Check if native Android browser
    else if ((tem = ua.match(/(?:Android )(\d+.[\d])/i)) !== null) {
      return {
        browser: `${mobile}android`,
        version: tem[1],
      };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);


    // Check if the browser is Chrome on iOS or IE
    const browsername = /CriOS/i.test(ua) ? 'chrome' : M[0].toLowerCase().replace('msie', 'ie');
    return {
      browser: mobile + browsername,
      version: M[1],
    };
  }

  whichOS() {
    const ua = navigator.userAgent;

    if (ua.includes('Windows')) {
      return 'windows';
    } else if (ua.includes('Mac')) {
      return 'mac-os';
    } else if (ua.includes('Android')) {
      return 'android';
    }
    return 'other';

  }
}

export const browserDetect = new BrowserDetect();
