
class WebpHelper {

  constructor() {
    this.isSupported = undefined;
  }

  detectBrowserSupport(callback) {
    if (this.isSupported !== undefined) {
      if (callback) callback();
      return;
    }
    const img = new Image();
    img.onload = () => {
      this.isSupported = img.width > 0 && img.height > 0;
      if (callback) callback();
    };
    img.onerror = () => {
      this.isSupported = false;
      if (callback) callback();
    };
    img.src = 'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==';
  }

}

export default new WebpHelper();
