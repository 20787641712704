/* eslint-disable max-len */
/* eslint-disable no-console */
/**
 *  @fileOverview Main app module
 *
 *  @author       Milosz Szarek <milosz.szarek@possible.com>
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 *  @author       Szymon Grzybek <szymon.grzybek@possible.com>
 *
 *  @requires     modules/module-controller
 */

import loadcss from 'fg-loadcss';
import loadSvgSprite from './utils/svg-sprite-loader';
import webpHelper from './utils/webp-helper';
import { browserDetect } from '../artofliving/utils/browser-detect';

browserDetect.init();

const getCurrentPage = () => (document.body.getAttribute('data-page').toLowerCase());

window.AMLConfig = window.AMLConfig || {};

class BootLoader {

  static bootCritical() {

    console.debug('AM BOOT cr');

    webpHelper.detectBrowserSupport();

    try {
      loadSvgSprite();
    } catch (e) {
      console.warn('SVG Sprite loading error', e);
    }

    try {
      BootLoader.loadCSS();
    } catch (e) {
      console.warn('CSS loading error', e);
    }

    this.page = getCurrentPage();

    if ('objectFit' in document.documentElement.style === false) {
      BootLoader.init(import(/* webpackChunkName: "object-fit" */ './utils/object-fit'), 'objectFit');
    }

    BootLoader.init(import(/* webpackChunkName: "video-controller" */ './modules/video-controller'), 'videoController');

    if (this.page.includes('-v2')) {
      BootLoader.init(import(/* webpackChunkName: "image-handler2" */ './modules/v2/image-handler'), 'imageHandler');
      if (BootLoader.check('[data-home-header]')) {
        BootLoader.init(import(/* webpackChunkName: "home-header" */ './modules/v2/home-header'), 'homeHeaderController');
      }
      if (BootLoader.check('[data-model-header]')) {
        BootLoader.init(import(/* webpackChunkName: "models-header" */ './modules/v2/models-header'), 'modelsHeaderBannerController');
      }
    }

    setTimeout(() => {
      BootLoader.init(import(/* webpackChunkName: "header" */ './modules/header'), 'header');
      BootLoader.bootNavigation();
    }, 1000);

    if (!this.page.includes('-v2')) {
      switch (this.page) {
        case 'model-page':
          BootLoader.init(import(/* webpackChunkName: "subnavigation" */ './modules/subnavigation'), 'subNavigation');
          BootLoader.init(import(/* webpackChunkName: "model-accordion" */ './modules/model-accordion'), 'modelAccordion');
          BootLoader.init(import(/* webpackChunkName: "specs-template" */ './modules/specs-template'), 'specsTemplate');
          break;
        case 'brand-page':
          if (BootLoader.check('[data-brand]')) {
            BootLoader.init(import(/* webpackChunkName: "brand-module" */ './modules/brand-module'), 'brandVideo');
          }
          break;
        case 'enquiry-page':
          BootLoader.init(import(/* webpackChunkName: "overlay-controller" */ './modules/overlay-controller'), 'overlayController');
          BootLoader.init(import(/* webpackChunkName: "enquire-controller" */ './modules/enquire-controller'), 'enquireController');
          break;
        case 'dealer-page':
          BootLoader.init(import(/* webpackChunkName: "overlay-controller" */ './modules/overlay-controller'), 'overlayController');
          BootLoader.init(import(/* webpackChunkName: "enquire-controller" */ './modules/enquire-controller'), 'enquireController');
          break;
        default:
          break;
      }

      if (BootLoader.check('[data-subnav-dealers]')) {
        BootLoader.init(import(/* webpackChunkName: "subnavigation-dealers" */ './modules/subnavigation-dealers'), 'subNavigationDealers');
      }
      if (BootLoader.check('[data-swiper]')) {
        BootLoader.init(import(/* webpackChunkName: "swiper-controller" */ './modules/swiper-controller'), 'swiperController');
      }
      if (BootLoader.check('[data-animate]')) {
        BootLoader.init(import(/* webpackChunkName: "animate-blocks" */ './utils/animate-blocks'), 'animateBlocks');
      }
      BootLoader.init(import(/* webpackChunkName: "colorize" */ './modules/colorize'), 'colorize');
      BootLoader.init(import(/* webpackChunkName: "image-handler" */ './modules/image-handler'), 'imageHandler');
    }

    if (BootLoader.check('[data-findadealer-config]')) {
      BootLoader.init(import(/* webpackChunkName: "enquire-controller" */ './modules/enquire-controller'), 'enquireController');
    } else if (BootLoader.check('[data-enquire-config], [data-enquire]')) {
      setTimeout(() => {
        BootLoader.init(import(/* webpackChunkName: "overlay-controller" */ './modules/overlay-controller'), 'overlayController');
        BootLoader.init(import(/* webpackChunkName: "enquire-controller" */ './modules/enquire-controller'), 'enquireController');
      }, 2000);
    }

    setTimeout(() => {
      BootLoader.bootNonCritical();
    }, 3000);

  }

  static bootNavigation() {
    return BootLoader.init(import(/* webpackChunkName: "search" */ './modules/search'), 'search')
      .then(() => BootLoader.init(import(/* webpackChunkName: "search-mobile" */ './modules/search-mobile'), 'searchMobile'))
      .then(() => BootLoader.init(import(/* webpackChunkName: "navigation" */ './modules/navigation'), 'navigation'))
      .then(() => BootLoader.init(import(/* webpackChunkName: "mobile-menu" */ './modules/mobile-menu'), 'mobileMenu'));
  }

  static bootNonCritical() {

    console.debug('AM BOOT ncr');
    this.page = getCurrentPage();

    if (this.page.includes('-v2')) {
      if (BootLoader.check('[data-enquire-button-menu]')) {
        BootLoader.init(import(/* webpackChunkName: "enquire-button-menu" */ './modules/v2/enquire-button-menu'), 'enquireButtonMenu');
      }
      if (BootLoader.check('section.main-nav-block')) {
        BootLoader.init(import(/* webpackChunkName: "main-navigation-block" */ './modules/v2/main-navigation-block'), 'mainNavigationBlockController');
      }
      if (BootLoader.check('section.news-block')) {
        BootLoader.init(import(/* webpackChunkName: "news-block" */ './modules/v2/news-block'), 'newsBlockController');
      }
      if (BootLoader.check('section.promo-block')) {
        BootLoader.init(import(/* webpackChunkName: "promo-block" */ './modules/v2/promo-block'), 'promoBlockController');
      }
      if (BootLoader.check('[data-small-image-block]')) {
        BootLoader.init(import(/* webpackChunkName: "small-image-block" */ './modules/v2/small-image-block'), 'smallImageBlockController');
      }
      if (BootLoader.check('[data-large-image-block]')) {
        BootLoader.init(import(/* webpackChunkName: "large-image-block" */ './modules/v2/large-image-block'), 'largeImageBlockController');
      }
      if (BootLoader.check('[data-engine-stats]')) {
        BootLoader.init(import(/* webpackChunkName: "engine-stats" */ './modules/v2/engine-stats'), 'engineStatsBlockController');
      }
      if (BootLoader.check('[data-model-highlight]')) {
        BootLoader.init(import(/* webpackChunkName: "model-highlight" */ './modules/v2/model-highlight'), 'modelHighlightController');
      }
      if (BootLoader.check('[data-model-carousel]')) {
        BootLoader.init(import(/* webpackChunkName: "model-carousel" */ './modules/v2/model-carousel'), 'modelCarouselController');
      }
      if (BootLoader.check('[data-model-configurator]')) {
        BootLoader.init(import(/* webpackChunkName: "model-configurator" */ './modules/v2/model-configurator'), 'modelConfiguratorController');
      }
      if (BootLoader.check('[data-lifestyle-block]')) {
        BootLoader.init(import(/* webpackChunkName: "lifestyle-block" */ './modules/v2/lifestyle-block'), 'lifestyleBlockController');
      }
      if (BootLoader.check('section.financial-services-block')) {
        BootLoader.init(import(/* webpackChunkName: "financial-services-block" */ './modules/v2/financial-services-block'), 'financialServicesBlockController');
      }
      if (BootLoader.check('section.sp-q-panel')) {
        BootLoader.init(import(/* webpackChunkName: "sp-q-panel" */ './modules/v2/sp-q-panel'), 'spQPanelController');
      }
      if (BootLoader.check('section.promo-block-short-block')) {
        BootLoader.init(import(/* webpackChunkName: "sp-promo-block-short" */ './modules/v2/sp-promo-block-short'), 'promoBlockShortController');
      }
      if (BootLoader.check('section.accessories-block')) {
        BootLoader.init(import(/* webpackChunkName: "accessories-block" */ './modules/v2/accessories-block'), 'accessoriesBlockController');
      }
      if (BootLoader.check('section.news-list-block')) {
        BootLoader.init(import(/* webpackChunkName: "news-list-block" */ './modules/v2/news-list-block'), 'newsListBlockController');
      }
      if (BootLoader.check('section.news-article-block')) {
        BootLoader.init(import(/* webpackChunkName: "news-article-block" */ './modules/v2/news-article-block'), 'newsArticleBlockController');
      }
      if (BootLoader.check('[transform-bg]')) {
        BootLoader.init(import(/* webpackChunkName: "transform-bg" */ './modules/v2/transform-bg'), 'transformBgController');
      }
      if (BootLoader.check('[data-bodytype-id]')) {
        BootLoader.init(import(/* webpackChunkName: "bodytype-event-emit" */ './modules/v2/bodytype-event-emit'), 'bodyTypeEventEmit');
      }
      if (BootLoader.check('[fid="sticky-nav"]')) {
        BootLoader.init(import(/* webpackChunkName: "model-sticky-nav" */ './modules/v2/model-sticky-nav'), 'modelStickyNav');
      }
      if (BootLoader.check('[data-sticky]')) {
        BootLoader.init(import(/* webpackChunkName: "sticky-elements-observer" */ './modules/v2/sticky-elements-observer'), 'stickyElementsObserver');
      }
      if (BootLoader.check('.cookie-bar')) {
        BootLoader.init(import(/* webpackChunkName: "cookie-bar" */ './modules/v2/cookie-bar'), 'cookieBarController');
      }
      if (BootLoader.check('[ol-trigger]')) {
        BootLoader.init(import(/* webpackChunkName: "ol-controller" */ './modules/v2/overlay-controller'), 'overlayController');
      }
      if (BootLoader.check('[data-location-switch]')) {
        BootLoader.init(import(/* webpackChunkName: "location-switch" */ '../artofliving/modules/location-switch'), 'locationSwitch');
      }
      if (BootLoader.check('.promo-swiper')) {
        BootLoader.init(import(/* webpackChunkName: "promo-swiper" */ './modules/v2/promo-swiper'), 'promoSwiperController');
      }
      if (BootLoader.check('.owner-guides')) {
        BootLoader.init(import(/* webpackChunkName: "owner-guides" */ './modules/v2/owner-guides'), 'ownerGuidesBlockController');
      }
    }

    if (!this.page.includes('-v2')) {
      if (BootLoader.check('[data-homepage-block]')) {
        BootLoader.init(import(/* webpackChunkName: "homepage-controller" */ './modules/homepage-controller'), 'homepageController');
      }
      if (BootLoader.check('[data-login-form]')) {
        BootLoader.init(import(/* webpackChunkName: "login-form" */ './modules/login-form'), 'loginForm');
      }
      if (BootLoader.check('[data-audio]')) {
        BootLoader.init(import(/* webpackChunkName: "audio-controller" */ './modules/audio-controller'), 'audioController');
      }
      if (BootLoader.check('[data-slider]')) {
        BootLoader.init(import(/* webpackChunkName: "slider-controller" */ './modules/slider-controller'), 'sliderController');
      }
      if (BootLoader.check('[data-accordion]')) {
        BootLoader.init(import(/* webpackChunkName: "accordion-controller" */ './modules/accordion-controller'), 'accordionController');
      }
      if (BootLoader.check('[data-experience]')) {
        BootLoader.init(import(/* webpackChunkName: "experience-controller" */ './modules/experience-controller'), 'experienceController');
      }
      if (BootLoader.check('[data-general-error]')) {
        BootLoader.init(import(/* webpackChunkName: "general-errors" */ './modules/general-errors'), 'generalError');
      }
      if (BootLoader.check('[data-parallax]')) {
        BootLoader.init(import(/* webpackChunkName: "parallax-controller" */ './modules/parallax-controller'), 'parallaxController');
      }
      if (BootLoader.check('[data-location-bing]')) {
        BootLoader.init(import(/* webpackChunkName: "location-bing" */ './modules/location-bing'), 'locationBing');
      }
      if (BootLoader.check('[data-tab-module]')) {
        BootLoader.init(import(/* webpackChunkName: "tab-controller" */ './modules/tab-controller'), 'tabModuleController');
      }
      if (BootLoader.check('[data-redirect-form]')) {
        BootLoader.init(import(/* webpackChunkName: "redirect-form" */ './modules/redirect-form'), 'redirectForm');
      }
      if (BootLoader.check('[data-count-up]')) {
        BootLoader.init(import(/* webpackChunkName: "count-up-controller" */ './modules/count-up-controller'), 'countUpController');
      }
      if (BootLoader.check('[data-gallery]')) {
        BootLoader.init(import(/* webpackChunkName: "gallery-controller" */ './modules/gallery-controller'), 'galleryController');
      }
      if (BootLoader.check('[data-quote]')) {
        BootLoader.init(import(/* webpackChunkName: "quote-controller" */ './modules/quote-controller'), 'quoteController');
      }
      if (BootLoader.check('[data-page-scroll]')) {
        BootLoader.init(import(/* webpackChunkName: "modelpage-controller" */ './modules/modelpage-controller'), 'modelpageController');
      }
      if (BootLoader.check('.cookies')) {
        BootLoader.init(import(/* webpackChunkName: "cookiebar" */ './modules/cookiebar'), 'cookieBar');
      }
      if (BootLoader.check('[data-simple-accordion]')) {
        BootLoader.init(import(/* webpackChunkName: "simple-accordion" */ './modules/simple-accordion'), 'simpleAccordion');
      }
      if (BootLoader.check('[data-contact-form]')) {
        BootLoader.init(import(/* webpackChunkName: "contact-form" */ './modules/contact-form'), 'contactForm');
      }
      if (BootLoader.check('[data-trackdays-form]')) {
        BootLoader.init(import(/* webpackChunkName: "trackdays-form" */ './modules/trackdays-form'), 'trackdaysForm');
      }
      if (BootLoader.check('[data-ecommerce]')) {
        BootLoader.init(import(/* webpackChunkName: "cart-bar-controller" */ './modules/ecommerce/cart-bar-controller'), 'cartController');
      }
      if (BootLoader.check('[data-ecommerce]')) {
        BootLoader.init(import(/* webpackChunkName: "cart-product-quantity" */ './modules/ecommerce/cart-product-quantity'), 'cartProductQuantityController');
      }
      if (BootLoader.check('[data-reservation]')) {
        BootLoader.init(import(/* webpackChunkName: "reservation-controller" */ './modules/ecommerce-club/reservation-controller'), 'clubReservationController');
      }
      if (BootLoader.check('[data-checkout]')) {
        BootLoader.init(import(/* webpackChunkName: "checkout-controller" */ './modules/ecommerce-club/checkout-controller'), 'clubCheckoutController');
      }
      if (BootLoader.check('[data-payment-form]')) {
        BootLoader.init(import(/* webpackChunkName: "payment-controller" */ './modules/ecommerce-club/payment-controller'), 'clubPaymentController');
      }
      if (BootLoader.check('[data-quantity-input]')) {
        BootLoader.init(import(/* webpackChunkName: "quantity-input-controller" */ './modules/quantity-input-controller'), 'quantityInputController');
      }
      if (BootLoader.check('form[data-form="global-landing"]')) {
        BootLoader.init(import(/* webpackChunkName: "global-landingpage-controller" */ './modules/global-landingpage-controller'), 'globalLandingPageController');
      }
      if (BootLoader.check('[data-datepicker]')) {
        BootLoader.init(import(/* webpackChunkName: "datepicker" */ './modules/datepicker'), 'datePicker');
      }
      if (BootLoader.check('[data-enquire-form]')) {
        BootLoader.init(import(/* webpackChunkName: "enquire-form-controller" */ './modules/enquire-form-controller'), 'formController');
      }
      setTimeout(() => {
        BootLoader.init(import(/* webpackChunkName: "location-switch" */ './modules/location-switch'), 'locationSwitch');
      }, 5000);
    }

    BootLoader.init(import(/* webpackChunkName: "helper" */ './utils/helper'), 'helper');
    BootLoader.init(import(/* webpackChunkName: "accessibility" */ './utils/accessibility'), 'accessibilityHelper')
      .then((accessibilityHelper) => {
        accessibilityHelper.removeClickFocus();
      });
  }

  static loadCSS() {
    const stylesEl = document.getElementById('styles');
    const cssUrl = stylesEl && stylesEl.getAttribute('href');
    if (cssUrl) {
      loadcss.loadCSS(cssUrl);
    }
  }


  static check(selector) {
    return (selector && document.querySelector(selector));
  }

  static init(importPromise, exportName) {
    return importPromise
      .then((module) => {
        const mod = module[exportName];
        if (mod && mod.init) {
          try {
            mod.init();
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
          }
        }
        window.AMModules = window.AMModules || {};
        window.AMModules[exportName] = mod;
        return mod;
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.warn('Exiting component', exportName);
      });
  }

  destroyModules() {
  }

}


/* --------------------------------- boot critical --------------------------------------- */

if (/comp|inter|loaded/.test(document.readyState)) {
  BootLoader.bootCritical();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    BootLoader.bootCritical();
  }, false);
}
