/**
 *  @fileOverview XHR based SVG loader
 *
 *  @author       Peter Schmiz <peter.schmiz@possible.com>
 */

const httpRequest = new XMLHttpRequest();

const addSpriteToDOM = (sprite) => {
  const svgElement = document.createElement('div');

  svgElement.style.display = 'none';
  svgElement.innerHTML = sprite;
  document.body.appendChild(svgElement);
};

const onChange = () => {
  if (httpRequest.readyState === XMLHttpRequest.DONE && httpRequest.status === 200) {
    addSpriteToDOM(httpRequest.responseText);
  }
};

const makeRequest = (url) => {
  httpRequest.onreadystatechange = onChange;
  httpRequest.open('GET', url);
  httpRequest.send();
};

const loadSvgSprite = () => {
  try {
    makeRequest(document.body.getAttribute('data-svg-url') || '/images/svg-sprite.svg');
  } finally {
    document.body.classList.add('svg-loaded');
  }
};

export default loadSvgSprite;
